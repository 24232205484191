import { List } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackIcon } from 'src/assets/ChevronLeft.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/ChevronRight.svg';
import { ReactComponent as HistoryIcon } from 'src/assets/sidebarIcon/history.svg';
import useGlobalFunction from 'src/hooks/useGlobalFunction';
import { RootState, useSelector } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import SidebarMenuItem from '../Menu/item';
import { MenuWrapper, SubMenuWrapper } from '../styles';

const History = () => {
  const { t } = useTranslation();
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );
  const { handleHistoryDrawer } = useGlobalFunction();

  const handleHistoryButtonClick = useCallback(
    (event) => {
      handleHistoryDrawer(event, showChatHistoryPanel ? false : true);
    },
    [showChatHistoryPanel]
  );

  return (
    <MenuWrapper>
      <SubMenuWrapper>
        <List component="div">
          <SidebarMenuItem
            key={0}
            active={true}
            name={t(T.chatHistory)}
            badgeTooltip={t(T.chatHistory)}
            isLink={false}
            showActiveClass={showChatHistoryPanel}
            icon={HistoryIcon}
            endIcon={
              showChatHistoryPanel ? (
                <BackIcon fill="white" height={'20px'} width={'20px'} />
              ) : (
                <ArrowIcon fill="white" />
              )
            }
            handleHistoryButtonClick={handleHistoryButtonClick}
          />
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  );
};

export default History;
