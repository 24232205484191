import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  drawerWidth,
  historyPanelWidth
} from 'src/layouts/AccentHeaderLayout/styles';
import { toggleChatHistoryPan } from 'src/redux/slices/chat';
import { RootState, useSelector } from 'src/redux/store';

const useGlobalFunction = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleHistoryDrawer = useCallback(
    (
      event: React.MouseEvent<
        HTMLButtonElement | HTMLAnchorElement | HTMLElement
      >,
      isOpen: boolean
    ) => {
      event.stopPropagation();
      dispatch(toggleChatHistoryPan(isOpen));
    },
    [dispatch]
  );

  const calculateSideBarDrawerWidth = () =>
    `calc(100vw - ${drawerWidth}px - ${
      showChatHistoryPanel && !isMobile ? `${historyPanelWidth}px` : '0px'
    })`;

  return { handleHistoryDrawer, calculateSideBarDrawerWidth };
};

export default useGlobalFunction;
