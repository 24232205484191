import { Drawer, useTheme } from '@mui/material';
import { RootState, useSelector } from 'src/redux/store';
import HistoryPanel from '../Sidebar/History/HistoryPanel';
import { drawerWidth, historyPanelWidth } from '../styles';

const ChatHistoryDrawer = () => {
  const theme = useTheme();
  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );

  return (
    <Drawer
      sx={{
        width: historyPanelWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: historyPanelWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.sidebar.background,
          marginLeft: `${drawerWidth}px`
        }
      }}
      variant="persistent"
      anchor="left"
      open={showChatHistoryPanel}
    >
      <HistoryPanel />
    </Drawer>
  );
};

export default ChatHistoryDrawer;
