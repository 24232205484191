import { styled } from '@mui/material';

export const drawerWidth = 250;
export const historyPanelWidth = 240;
export const miniDrawerWidth = 60;
const marginLeft = drawerWidth - miniDrawerWidth;

interface MainProps {
  isSideBarOpen?: boolean;
  isHidden?: boolean;
  drawerMaxWidth?: string;
}

export const Main = styled('main')<MainProps>(
  ({ theme, isSideBarOpen, isHidden, drawerMaxWidth }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: '0',
    display: 'flex',
    width: '100%',
    maxWidth: isHidden ? '100%' : drawerMaxWidth,
    ...(!isSideBarOpen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      maxWidth: `calc(100% - ${miniDrawerWidth}px)`,
      marginLeft: `-${marginLeft}px`
    }),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginLeft: '0',
      maxHeight: `calc(100% - ${miniDrawerWidth}px)`,
      marginTop: `${isHidden ? 0 : miniDrawerWidth}px`
    }
  })
);
