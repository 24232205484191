import { ArticleOutlined } from '@mui/icons-material';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { IconResource } from 'src/redux/slices/data';
import { initialColorList } from 'src/theme/customColors';
import { IAiModel, StringKeys } from 'src/types/base';
import {
  FAVOURITE_TYPE,
  RESOURCE_PROVIDERS,
  RESOURCE_TYPE
} from 'src/types/enum';
import { getInitials } from 'src/utils/utils';
import {
  AssistantIconContainer,
  CustomCollectionIcon,
  CustomFolderIcon,
  OneDriveCollectionIcon,
  OneDriveFolderIcon,
  SharePointCollectionIcon,
  SharePointFolderIcon,
  StyledConfluenceCollectionIcon,
  StyledConfluenceFolderIcon
} from '../styles';
import WorkspaceIcon from 'src/components/WorkspaceIcon';

const AssistantIcon = ({ icons, item }) => {
  return (
    <AssistantIconContainer
      style={{
        backgroundImage: `url(${
          icons?.filter((icon) => icon.id == item?.settings?.icon_id)?.[0]
            ?.icon || ''
        })`
      }}
    />
  );
};

const defaultItem = {
  icon: ArticleOutlined,
  link: '/data-library'
};

const getItemData = (
  item: StringKeys,
  favouriteType: string,
  icons?: IconResource[],
  configuredModels?: IAiModel[]
) => {
  let itemData;
  if (favouriteType === FAVOURITE_TYPE.ASSISTANT) {
    itemData = {
      hasPermission: !(
        (item?.settings?.web_search_enabled ||
          item?.has_permission ||
          item?.settings?.world_knowledge) &&
        !!configuredModels.filter(
          (model) => model.id === parseInt(item?.settings.model_id)
        )[0]
      )
    };
    if (item?.settings?.icon_id) {
      itemData = {
        ...itemData,
        icon: () => <AssistantIcon icons={icons} item={item} />
      };
    } else {
      itemData = {
        ...itemData,
        icon: DefaultIcon
      };
    }
  } else {
    switch (item.code) {
      case RESOURCE_TYPE.WORKSPACE:
      case RESOURCE_TYPE.PUBLIC_WORKSPACE:
      case RESOURCE_TYPE.FOUND_WORKSPACE:
      case RESOURCE_TYPE.PRIVATE_WORKSPACE:
        itemData = {
          icon: () => (
            <WorkspaceIcon
              initials={item?.initials}
              initialsColor={item?.initials_color}
              displayName={item?.display_name}
            />
          ),
          link: `/data-library/collections/${item.item_id}`
        };
        break;
      case RESOURCE_TYPE.COLLECTION:
      case RESOURCE_TYPE.FOUND_COLLECTION:
        itemData = {
          icon:
            item?.provider_type === RESOURCE_PROVIDERS.ONEDRIVE
              ? OneDriveCollectionIcon
              : item?.provider_type === RESOURCE_PROVIDERS.SHAREPOINT
              ? SharePointCollectionIcon
              : item?.provider_type === RESOURCE_PROVIDERS.CONFLUENCE
              ? StyledConfluenceCollectionIcon
              : CustomCollectionIcon,
          link: `/data-library/resources/${item.item_id}`
        };
        break;
      case RESOURCE_TYPE.FOLDER:
        itemData = {
          icon:
            item?.provider_type === RESOURCE_PROVIDERS.ONEDRIVE
              ? OneDriveFolderIcon
              : item?.provider_type === RESOURCE_PROVIDERS.SHAREPOINT
              ? SharePointFolderIcon
              : item?.provider_type === RESOURCE_PROVIDERS.CONFLUENCE
              ? StyledConfluenceFolderIcon
              : CustomFolderIcon,
          link: `/data-library/resources/${item.item_id}`
        };
        break;
      default:
        itemData = defaultItem;
    }
    itemData['hasChatPermission'] =
      item.access_control?.['query-files-folders']?.value;
  }

  return itemData;
};

export default getItemData;
