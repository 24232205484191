import {
  Box,
  Container,
  CssBaseline,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import UserOnboard from 'src/components/UserOnboard';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useGlobalFunction from 'src/hooks/useGlobalFunction';
import { RootState, useSelector } from 'src/redux/store';

import ChatHistoryDrawer from './Drawers/ChatHistoryDrawer';
import DesktopMinimizedDrawer from './Drawers/DesktopMinimizedDrawer';
import MobileMinimizedDrawer from './Drawers/MobileMinimizedDrawer';
import SidebarDrawer from './Drawers/SidebarDrawer';
import { Main } from './styles';

const AccentHeaderLayout: FC = () => {
  const theme = useTheme();
  const { handleHistoryDrawer, calculateSideBarDrawerWidth } =
    useGlobalFunction();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isSidebarOpen } = useContext(SidebarContext);

  const [isShareChatPath, setIsShareChatPath] = useState<boolean>(true);
  const { isOnboarded, isLangInitialized } = useSelector(
    (state: RootState) => state.data
  );
  const { pathname } = useLocation();

  const { showChatHistoryPanel } = useSelector(
    (state: RootState) => state.chat
  );

  useEffect(() => {
    if (pathname)
      setIsShareChatPath(pathname.split('/').includes('share-chat'));
  }, [pathname]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />

      {!isShareChatPath && (
        <Box display="flex" height="100vh">
          <SidebarDrawer />
          {showChatHistoryPanel && !isMobile && <ChatHistoryDrawer />}
        </Box>
      )}

      {/* Desktop minimized version  */}
      <DesktopMinimizedDrawer />

      {/* Mobile minimized version */}
      <MobileMinimizedDrawer isShareChatPath={isShareChatPath} />

      <Main
        isSideBarOpen={isSidebarOpen}
        isHidden={isShareChatPath}
        drawerMaxWidth={calculateSideBarDrawerWidth()}
        onClick={(event) => handleHistoryDrawer(event, false)}
      >
        <Container
          maxWidth={false}
          disableGutters
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Outlet />
          {!isOnboarded && isLangInitialized && !isMobile && <UserOnboard />}
        </Container>
      </Main>
    </Box>
  );
};

export default AccentHeaderLayout;
