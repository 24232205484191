import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useGlobalFunction from 'src/hooks/useGlobalFunction';
import Sidebar from '../Sidebar';
import { drawerWidth } from '../styles';

const SidebarDrawer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleHistoryDrawer } = useGlobalFunction();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: `1px solid `
        }
      }}
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={isSidebarOpen}
      onClose={toggleSidebar}
      {...(isMobile ? { keepMounted: true } : {})}
      onClick={(event) => handleHistoryDrawer(event, false)}
    >
      <Sidebar />
    </Drawer>
  );
};

export default SidebarDrawer;
