import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { CHAT_LIST_PAGE_SIZE } from 'src/content/Chat/utils';
import { IAiModel, StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { PdfDocument } from 'src/types/document';
import { CHAT_CLASS, CHAT_SOURCE_TYPES } from 'src/types/enum';
import { v4 as uuidV4 } from 'uuid';

interface StreamEventTimings {
  querySendTime?: number;
  queryAcknowledgeTime?: number;
  responseStreamStartTime?: number;
  responseStreamEndTime?: number;
}

interface ChatContext {
  tags: any[];
  openedDocuments: PdfDocument[];
  isDocumentViewerOpen: boolean;
  selectedDocumentIndex: number;
  streamEventTimings: StreamEventTimings;
  chat_type: CHAT_SOURCE_TYPES;
  chatModel: IAiModel;
  messageId?: number;
  documentContext?: {
    id: number;
    name: string;
    workspaceName?: string;
    assistant_id?: number;
  };
  shareChatData?: {
    link: string;
    sharedOn: string;
  };
  web_search_enabled?: boolean;
  chat_class?: CHAT_CLASS;
  rid?: number;
  ts?: string;
  chatFiles?: any[];
  noOfChatAttachments?: number;
  hasImageAttachments?: boolean;
}

const initialState: {
  selectedChat: number;
  chatCreationError: boolean;
  isParameterModalOpen: boolean;
  chats: ChatData[];
  chatContexts: {
    [id: number]: ChatContext;
  };
  currentlyDownloadingDocuments: number[];
  currentPageNumber: number;
  showChatHistoryPanel: boolean;
} = {
  chats: [],
  selectedChat: undefined,
  chatCreationError: false,
  isParameterModalOpen: false,
  chatContexts: {},
  currentlyDownloadingDocuments: [],
  currentPageNumber: 0,
  showChatHistoryPanel: false
};

export const defaultChatContext = {
  tags: [],
  openedDocuments: [],
  isDocumentViewerOpen: false,
  selectedDocumentIndex: 0,
  streamEventTimings: {},
  chat_type: CHAT_SOURCE_TYPES.EmpowerGPT,
  chatModel: null,
  web_search_enabled: false,
  chat_class: CHAT_CLASS?.NORMAL,
  chatFiles: [],
  noOfChatAttachments: 0,
  hasImageAttachments: false
};

const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChats(state, { payload }) {
      state.chats = payload;
    },
    updateChatData(
      state,
      action: PayloadAction<{ chatId: number; chatData: any }>
    ) {
      const { chatId, chatData } = action.payload;
      const chatIndex = findIndex(state.chats, { id: chatId });
      if (chatIndex > -1) {
        state.chats[chatIndex] = { ...state.chats[chatIndex], ...chatData };
      }
    },
    appendChat(state, action: PayloadAction<ChatData>) {
      if (
        state.chats.length >
        CHAT_LIST_PAGE_SIZE * (state.currentPageNumber + 1)
      ) {
        state.chats.splice(state.chats.length - 1, 1);
      }
      state.chats.unshift(action.payload);
    },
    setSelectedChat(state, action) {
      state.selectedChat = action.payload;
    },
    openParameterModal(state) {
      state.isParameterModalOpen = true;
    },
    closeParameterModal(state) {
      state.isParameterModalOpen = false;
    },
    createChatSnapshot(
      state,
      action: PayloadAction<{ chatId: number; chatContext?: ChatContext }>
    ) {
      const { chatId, chatContext } = action.payload;
      const selectedChatContext = state.chatContexts[chatId];
      if (
        !selectedChatContext ||
        selectedChatContext?.documentContext?.id !==
          chatContext?.documentContext?.id
      ) {
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: chatContext ? { ...chatContext } : defaultChatContext
        };
      }
    },
    updateChatTags(state, { payload }) {
      if (state.chatContexts[payload.chatId])
        state.chatContexts[payload.chatId].tags = payload.tags;
    },
    updateChatModel(
      state,
      action: PayloadAction<{ chatId: number; chatModel: IAiModel }>
    ) {
      const { chatId, chatModel } = action.payload;
      state.chatContexts[chatId].chatModel = chatModel;
    },
    updateChatWebSearch(
      state,
      action: PayloadAction<{ chatId: number; isWebSearch: boolean }>
    ) {
      const { chatId, isWebSearch } = action.payload;
      state.chatContexts[chatId].web_search_enabled = isWebSearch;
    },
    clearChatSnapshot(state, { payload }: PayloadAction<number>) {
      if (state.chatContexts[payload]) {
        delete state.chatContexts[payload];
      }
    },
    updateChatSourceType(state, { payload }) {
      state.chatContexts[0].chat_type = payload;
    },
    updateOpenedDocument(
      state,
      action: PayloadAction<{
        chatId: number;
        document: {
          pageNumber: number;
          name: string;
          id: number;
          index: number;
        };
      }>
    ) {
      const { payload } = action;
      if (
        state.chatContexts[payload.chatId].openedDocuments[
          payload.document.index
        ].pageNumber !== payload.document.pageNumber
      ) {
        state.chatContexts[payload.chatId].openedDocuments[
          payload.document.index
        ] = {
          ...state.chatContexts[payload.chatId].openedDocuments[
            payload.document.index
          ],
          ...payload.document
        };
      }
      state.chatContexts[payload.chatId].isDocumentViewerOpen = true;
      if (
        state.chatContexts[payload.chatId].selectedDocumentIndex !==
        payload.document.index
      ) {
        state.chatContexts[payload.chatId].selectedDocumentIndex =
          payload.document.index;
      }
    },
    addOpenedDocument(
      state,
      action: PayloadAction<{
        messageId?: number;
        chatId: number;
        document: PdfDocument;
      }>
    ) {
      const { payload } = action;
      let uuid = uuidV4();

      state.chatContexts[payload.chatId].openedDocuments.unshift({
        ...payload.document,
        uuid: uuid
      });
      state.chatContexts[payload.chatId].isDocumentViewerOpen = true;
      state.chatContexts[payload.chatId].messageId = payload.messageId;
    },
    removeOpenedDocument(
      state,
      action: PayloadAction<{ chatId: number; documentIndex: number }>
    ) {
      const { chatId, documentIndex } = action.payload;
      state.chatContexts[chatId].openedDocuments.splice(documentIndex, 1);
      if (!state.chatContexts[chatId].openedDocuments.length) {
        state.chatContexts[chatId].isDocumentViewerOpen = false;
      }
    },
    openDocumentViewer(state, { payload }: PayloadAction<number>) {
      state.chatContexts[payload].isDocumentViewerOpen = true;
    },
    closeDocumentViewer(state, { payload }: PayloadAction<number>) {
      if (payload in state.chatContexts) {
        state.chatContexts[payload].isDocumentViewerOpen = false;
        state.chatContexts[payload].openedDocuments = [];
      }
    },
    addCurrentlyDownloadingDocuments(state, action: PayloadAction<number>) {
      state.currentlyDownloadingDocuments.push(action.payload);
    },
    removeCurrentlyDownloadingDocuments(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.currentlyDownloadingDocuments =
        state.currentlyDownloadingDocuments.filter((item) => item !== payload);
    },
    updateStreamEventTimings(
      state,
      action: PayloadAction<{
        chatId: number;
        streamEventTimings: StreamEventTimings;
      }>
    ) {
      const { chatId, streamEventTimings } = action.payload;
      if (state.chatContexts[chatId])
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: {
            ...state.chatContexts[chatId],
            streamEventTimings: { ...streamEventTimings }
          }
        };
    },
    createShareChat(
      state,
      action: PayloadAction<{
        chatId: number;
        shareChatData: { link: string; sharedOn: string };
      }>
    ) {
      const { chatId, shareChatData } = action.payload;
      if (state.chatContexts[chatId])
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: {
            ...state.chatContexts[chatId],
            shareChatData: { ...shareChatData }
          }
        };
    },
    updateChatsPageNumber(state, { payload }) {
      state.currentPageNumber = payload || 0;
    },
    toggleChatHistoryPan(state, { payload }) {
      state.showChatHistoryPanel = payload;
    },
    updateChatAttachmentsCount(
      state,
      action: PayloadAction<{
        chatId: number;
        chatAttachmentsCount: number;
        hasImageAttachments?: boolean;
      }>
    ) {
      const {
        chatId,
        chatAttachmentsCount,
        hasImageAttachments = false
      } = action.payload;
      state.chatContexts[chatId].noOfChatAttachments = chatAttachmentsCount;
      state.chatContexts[chatId].hasImageAttachments = hasImageAttachments;
    },
    updateChatAttachments(
      state,
      action: PayloadAction<{ chatId: number; chatFiles: StringKeys[] }>
    ) {
      const { chatId, chatFiles } = action.payload;
      state.chatContexts[chatId].chatFiles = chatFiles;
    },
    setChatCreationError(state, action: PayloadAction<{ isError: boolean }>) {
      const { isError } = action.payload;
      state.chatCreationError = isError;
    }
  }
});

export default ChatSlice;

export const reducer = ChatSlice.reducer;

export const {
  setChats,
  updateChatData,
  appendChat,
  setSelectedChat,
  openParameterModal,
  closeParameterModal,
  createChatSnapshot,
  updateChatTags,
  updateChatModel,
  updateChatWebSearch,
  clearChatSnapshot,
  addOpenedDocument,
  updateOpenedDocument,
  removeOpenedDocument,
  openDocumentViewer,
  closeDocumentViewer,
  addCurrentlyDownloadingDocuments,
  removeCurrentlyDownloadingDocuments,
  updateStreamEventTimings,
  updateChatSourceType,
  createShareChat,
  updateChatsPageNumber,
  toggleChatHistoryPan,
  updateChatAttachmentsCount,
  updateChatAttachments,
  setChatCreationError
} = ChatSlice.actions;
