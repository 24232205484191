import { FC } from 'react';
import { NavLinkProps, NavLink as RouterNavLink } from 'react-router-dom';

const NavLink: FC<NavLinkProps> = ({ className, ...rest }) => {
  return (
    <RouterNavLink
      className={({ isActive }) =>
        `${className} ${isActive ? 'Mui-active' : ''}`
      }
      {...rest}
    />
  );
};

export default NavLink;