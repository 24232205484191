import { Add } from '@mui/icons-material';
import { IconButton, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { resetAssistantContext } from 'src/content/Documents/utils/gridUtils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  closeDocumentViewer,
  createChatSnapshot,
  setChatCreationError,
  setSelectedChat
} from 'src/redux/slices/chat';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { CHAT_PRE_CREATION_ID } from 'src/utils/constants';
import { TRANSLATION_CONSTANTS } from 'src/utils/translations';

const CreateChat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toggleSidebar } = useContext(SidebarContext);
  const { selectedChat, chatCreationError } = useSelector(
    (state: RootState) => state.chat
  );
  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  const handleCreateNewChat = () => {
    if (selectedChat !== 0 || routeState || chatCreationError) {
      if (chatCreationError) dispatch(setChatCreationError({ isError: false }));
      dispatch(closeDocumentViewer(selectedChat));
      dispatch(setSelectedChat(undefined));
      dispatch(createChatSnapshot({ chatId: CHAT_PRE_CREATION_ID }));
      resetAssistantContext(dispatch);
      setTimeout(() => {
        dispatch(setSelectedChat(0));
      }, 100);
    }
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(1.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Button
        component={NavLink}
        variant="contained"
        color="secondary"
        sx={{
          borderRadius: '6px',
          width: 'calc(100% - 48px)'
        }}
        to="/chat"
        onClick={handleCreateNewChat}
        size="large"
        startIcon={<Add />}
      >
        <Typography variant="h4">
          {t(TRANSLATION_CONSTANTS.createNewChat)}
        </Typography>
      </Button>

      {toggleSidebar && (
        <IconButton
          sx={{
            marginLeft: '12px',
            padding: '3px'
          }}
          color="secondary"
          onClick={toggleSidebar}
        >
          <SidebarIcon height={'24px'} width={'24px'} />
        </IconButton>
      )}
    </Box>
  );
};

export default CreateChat;
