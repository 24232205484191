import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import { Box, Divider, lighten, styled } from '@mui/material';
import { ReactComponent as ConfluenceCollectionIcon } from 'src/assets/documentIcons/collection-confluence.svg';
import { ReactComponent as CollectionIcon } from 'src/assets/documentIcons/collection-icon.svg';
import { ReactComponent as OneDriveIcon } from 'src/assets/documentIcons/collection-onedrive-icon.svg';
import { ReactComponent as SharepointIcon } from 'src/assets/documentIcons/collection-sharepoint-icon.svg';
import { ReactComponent as ConfluenceFolderIcon } from 'src/assets/documentIcons/confluence-folder-icon.svg';
import { ReactComponent as FolderIcon } from 'src/assets/documentIcons/folder-icon.svg';
import { ReactComponent as OnedriveFolderIcon } from 'src/assets/documentIcons/onedrive-folder-icon.svg';
import { ReactComponent as SharepointFolderIcon } from 'src/assets/documentIcons/sharepoint-folder-icon.svg';

export const SidebarWrapper = styled(Box)(
  ({ theme }) => `
          width: ${theme.sidebar.width};
          min-width: ${theme.sidebar.width};
          color: ${theme.sidebar.textColor};
          background: ${theme.sidebar.background};
          box-shadow: ${theme.sidebar.boxShadow};
          position: relative;
          z-index: 7;
          height: 100%;
  `
);

export const MenuWrapper = styled(Box)(
  ({ theme }) => `
      .MuiList-root {
        margin-bottom:  0; 
        padding: 0;
  
        & > .MuiList-root {
          padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
        }
      }
  
      .MuiListSubheader-root {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${theme.typography.pxToRem(12)};
        color: ${theme.sidebar.menuItemIconColor};
        padding: ${theme.spacing(0.2, 3.5)};
        line-height: 1.4;
      }
  `
);

export const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
      .MuiList-root {
  
        .MuiListItem-root {
          padding: 2px 0;
          padding-left: ${theme.spacing(0.5)};
          
          padding-right: ${theme.spacing(0.5)};
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(3.5)};
  
            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
      
          .MuiButton-root {
            display: flex;
            color: ${theme.sidebar.menuItemColor};
            background-color: ${theme.sidebar.menuItemBg};
            width: 100%;
            justify-content: flex-start;
            font-weight: 400;
      
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              transition: ${theme.transitions.create(['color'])};
  
              .MuiSvgIcon-root {
                font-size: inherit;
                transition: none;
              }
            }
  
            .MuiButton-startIcon {
              font-size: ${theme.typography.pxToRem(20)};
              margin-right: ${theme.spacing(1)};
              color: ${theme.sidebar.menuItemIconColor};
            }
            
            .MuiButton-endIcon {
              margin-left: auto;
              opacity: .8;
              font-size: ${theme.typography.pxToRem(20)};
            }
  
            &.Mui-active,
            &:hover {
              background-color: ${theme.sidebar.menuItemBgActive};
              color: ${theme.sidebar.menuItemColorActive};
  
              .MuiButton-startIcon,
              .MuiButton-endIcon {
                  color: ${theme.sidebar.menuItemIconColorActive};
              }

              .MuiButton-root {
                display: flex;
                color: ${theme.colors.primary.main};
                background-color: ${theme.colors.secondary.main};
                width: max-content;
                height: 22px;
                justify-content: flex-start;
                font-weight: 700;
  
                &:hover {
                  color: ${theme.colors.primary.dark};
                  background-color: ${theme.colors.secondary.dark};
                }
              }
            }

            .MuiButton-root {
              display: none;
            }
          }
  
          &.Mui-children {
            flex-direction: column;
  
            .MuiBadge-root {
              position: absolute;
              right: ${theme.spacing(7.5)};
            }
          }
  
          .MuiCollapse-root {
            width: 100%;
  
            .MuiList-root {
              padding: ${theme.spacing(1, 0)};
            }
  
            .MuiListItem-root {
              padding: 0;
              padding-left: ${theme.spacing(1.2)};
  
              .MuiListItem-root {
                padding: 0;
              }
  
              .MuiButton-root {
                .MuiBadge-root {
                  right: ${theme.spacing(3.5)};
                }
              }
  
              .MuiButton-root {
                &.Mui-active,
                &:hover {
                  background-color: ${theme.sidebar.menuItemBgActive};
                }
              }
            }
          }
        }
      }
  `
);

export const CustomDivider = styled(Divider)(
  ({ theme }) => `
      border-color: ${theme.colors.alpha.white[10]};
      background-color: ${theme.colors.alpha.white[10]};
      color: ${theme.colors.alpha.white[10]};
  `
);

export const CustomCollectionIcon = styled(CollectionIcon)(
  ({ theme }) => `
      width: 28px;
      height: 28px;
  `
);

export const CustomFolderIcon = styled(FolderIcon)(
  ({ theme }) => `
      width: 28px;
      height: 28px;
  `
);

export const SharePointCollectionIcon = styled(SharepointIcon)({
  width: '28px',
  height: '28px'
});

export const OneDriveCollectionIcon = styled(OneDriveIcon)({
  width: '28px',
  height: '28px'
});

export const StyledConfluenceCollectionIcon = styled(ConfluenceCollectionIcon)({
  width: '28px',
  height: '28px'
});

export const SharePointFolderIcon = styled(SharepointFolderIcon)({
  width: '28px',
  height: '28px'
});

export const OneDriveFolderIcon = styled(OnedriveFolderIcon)({
  width: '28px',
  height: '28px'
});

export const StyledConfluenceFolderIcon = styled(ConfluenceFolderIcon)({
  width: '28px',
  height: '28px'
});

export const CustomStarRoundedIcon = styled(StarOutlineRoundedIcon)(
  ({ theme }) => `
      width: 24px;
      height: 24px;
  `
);

export const ChatListBox = styled(Box)(
  ({ theme }) => `
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 0;
    &:hover {
      background: linear-gradient(to right, ${lighten(
        theme.sidebar.menuItemBg,
        0.35
      )} 63%, ${theme.sidebar.menuItemBg});
      .chat-action-buttons {
        display: flex;
        padding-right: 8px;
      }
      &:hover {
        .chat-button {
          background: linear-gradient(to right, ${lighten(
            theme.sidebar.menuItemBg,
            0.3
          )} 100%, ${theme.sidebar.menuItemBg});
        }
      }
    }
  `
);

export const ChatListActionButtonsBox = styled(Box)(
  `width: 70px;
  height: 100%;
  display: none;
  flex-direction: row-reverse;`
);

export const AssistantIconContainer = styled(Box)(
  `border-radius: 4px;
    height: 28px;
    width: 28px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center`
);
