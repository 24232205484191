import { Drawer, IconButton, useTheme } from '@mui/material';
import { useContext } from 'react';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { miniDrawerWidth } from '../styles';

const DesktopMinimizedDrawer = () => {
  const theme = useTheme();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  return (
    <Drawer
      sx={{
        width: `${miniDrawerWidth}px`,
        flexShrink: 0,
        position: 'absolute',
        left: 0,
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        '& .MuiDrawer-paper': {
          width: `${miniDrawerWidth}px`,
          boxSizing: 'border-box',
          backgroundColor: theme.sidebar.background
        }
      }}
      variant="persistent"
      anchor="left"
      open={!isSidebarOpen}
    >
      <IconButton
        aria-label="open drawer"
        onClick={toggleSidebar}
        color="secondary"
        sx={{
          my: theme.spacing(2),
          mx: 'auto',
          ...(isSidebarOpen && { display: 'none' }),
          padding: theme.spacing(1.4)
        }}
      >
        <SidebarIcon height="24px" width="24px" />
      </IconButton>
    </Drawer>
  );
};

export default DesktopMinimizedDrawer;
