import {
  FILE_EXTENSIONS,
  RESOURCE_TYPE,
  SUPPORTED_UPLOAD_FILES_FORMAT
} from 'src/types/enum';

export const TWENTY_FOUR_HOUR_FORMAT = 'dd MMM yyyy HH:mm';
export const DATE_FORMAT = 'MMMM dd yyyy';
export const DATE_FILTER_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'hh:mm';
export const MONTH_YEAR_FORMAT = 'MMM, yyyy';
export const DOCUMENT_FORMAT = 'EEEE dd/MM/yyyy hh:mm a';
export const EMAIL_REG_EXP = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
export const FILE_FORMAT = {
  [SUPPORTED_UPLOAD_FILES_FORMAT.PPT]: ['.'.concat(FILE_EXTENSIONS.PPT)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.PPTX]: ['.'.concat(FILE_EXTENSIONS.PPTX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.PDF]: ['.'.concat(FILE_EXTENSIONS.PDF)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.DOC]: ['.'.concat(FILE_EXTENSIONS.DOC)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.DOCX]: ['.'.concat(FILE_EXTENSIONS.DOCX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.XLS]: ['.'.concat(FILE_EXTENSIONS.XLS)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.XLSX]: ['.'.concat(FILE_EXTENSIONS.XLSX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODS]: ['.'.concat(FILE_EXTENSIONS.ODS)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODP]: ['.'.concat(FILE_EXTENSIONS.ODP)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODT]: ['.'.concat(FILE_EXTENSIONS.ODT)]
};

export const ROUTES = {
  NAMESPACE_ROUTE: 'workspaces',
  WORKSPACE_ROUTE: 'collections',
  RESOURCES_ROUTE: 'resources'
};

export const DATA_LIBRARY_ROUTES = {
  [RESOURCE_TYPE.NAMESPACE]: ROUTES.NAMESPACE_ROUTE,
  [RESOURCE_TYPE.WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.PUBLIC_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.PRIVATE_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.FOUND_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.COLLECTION]: ROUTES.RESOURCES_ROUTE,
  [RESOURCE_TYPE.FOUND_COLLECTION]: ROUTES.RESOURCES_ROUTE,
  [RESOURCE_TYPE.FOLDER]: ROUTES.RESOURCES_ROUTE,
  [RESOURCE_TYPE.FILE]: ROUTES.RESOURCES_ROUTE
};

export const PUBLIC_ROUTES = ['/signup', '/privacypolicy'];

export const VALID_CHAT_FILES_EXTENSION =
  '.png, .jpeg, .jpg, .gif, .docx, .doc, .pptx, .ppt, .xlsx, .xls, .odt, .odp, .ods, .pdf, .dot, .rtf';

export const MAX_CHAT_UPLOAD_FILES = 4;
export const MAX_CHAT_FILE_SIZE = 25 * 1024 * 1024;
export const CHAT_PRE_CREATION_ID = 0;
export const MAX_CHAT_ATTACHMENT_SIZE = 25;

export const SELECTED_DOC = 'selectedDoc';
export const ATLASSIAN_ACCOUNT_ID = 'userAtlassianAccountId';
export const USER_ATLASSIAN_PERMISSION = 'userAtlassianPermission';
export const ATLASSIAN_ACCESS_TOKEN = 'atlassianAccessToken';
export const ATLASSIAN_REFRESH_TOKEN = 'atlassianRefreshToken';
export const ATLASSIAN_BASE_AUTH_URL =
  'https://auth.atlassian.com/authorize?audience=api.atlassian.com';
export const ATLASSIAN_CONTENT_IMPORT_SCOPE =
  'scope=read:confluence-content.summary read:confluence-space.summary offline_access';
export const ATLASSIAN_AUTH_RESPONSE_TYPE = 'code';
export const ATLASSIAN_EXCHANGE_CODE_URL =
  'https://auth.atlassian.com/oauth/token';
export const ATLASSIAN_AUTH_INTERVAL_TIME = 500;
export const ROOT_FOLDER_TYPES = {
  MY: 'my',
  SHARED: 'shared'
};
export const STATUS_ROUTE = 'status/';
