import {
  AdminPanelSettingsOutlined,
  AnalyticsOutlined,
  GroupsOutlined,
  LockOutlined,
  ManageAccountsOutlined,
  SettingsOutlined,
  DonutLarge
} from '@mui/icons-material';
import { SETTINGS_TABS } from 'src/content/Settings/utils';
import { StringKeys } from 'src/types/base';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getPopupRoutes = (
  userAppPermissions: StringKeys,
  isNonTrialUser: boolean
): {
  to: string;
  startIcon: JSX.Element;
  title: string;
  onClick?: () => void;
}[] => {
  const routes = [];
  if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USERS]?.value) {
    routes.push({
      to: '/users',
      startIcon: <ManageAccountsOutlined />,
      title: T.users
    });
  }
  if (
    isNonTrialUser &&
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROLES]?.value
  ) {
    routes.push({
      to: '/roles',
      startIcon: <AdminPanelSettingsOutlined />,
      title: T.rolesTitle
    });
  }
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USER_GROUPS]?.value ||
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_OWN_USER_GROUPS]?.value
  ) {
    routes.push({
      to: '/user-groups',
      startIcon: <GroupsOutlined />,
      title: T.userGroups
    });
  }
  if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ANALYTICS]?.value) {
    routes.push({
      to: '/usage',
      startIcon: <AnalyticsOutlined />,
      title: T.usage
    });
    routes.push({
      to: '/internal-analytics',
      startIcon: <DonutLarge />,
      title: T.analytics
    });
    routes.push({
      to: `/settings/${SETTINGS_TABS.SYSTEM_SETTINGS}`,
      startIcon: <SettingsOutlined />,
      title: T.settings
    });
  } else {
    routes.push({
      to: `/settings/${SETTINGS_TABS.PROFILE}`,
      startIcon: <SettingsOutlined />,
      title: T.settings
    });
  }
  return routes;
};
